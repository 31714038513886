<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import rejectionModal from "../../common/modals/rejectionModal";
import approvalModal from "./components/approveRequestModal";
import { barChart } from "./data-overview";

/**
 * Projects-overview component
 */
export default {
  page: {
    title: "Project Overview",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, rejectionModal, approvalModal },
  data() {
    return {
      barChart: barChart,
      // title: "Project Overview",
      uuid: "",
      items: [
        {
          text: "Requêtes",
          href: "/",
        },
        {
          text: "Repas emporté",
          active: true,
        },
      ],

      tamrData: {
        id: 0,
        ref: "",
        description: "",
        requestedBy: "",
        requestDate: "",
        requestType: "",
        requestLocationType: "",
        location_txt: "",
        requestLocation: "",
        requestUrgency: "",
        status: "",
        statusPlain: "",
        maintenanceOrderRef: "",
        maintenanceOrder: [],
        modelType: "",
        rejectedBy: "",
        approvedBy: "",
        rejectionReason: "",
        rejectionDate: "",
        reception: [],
        entries : [],
      },
    };
  },

  mounted() {
    this.uuid = this.$route.params.uid;
    this.fetchSingleTakeawayRequestData();
  },

  methods: {
    

    fetchSingleTakeawayRequestData() {
      var _this = this;
      this.$http
        .post("/ops/requests/takeawaymeals/getSingleRequestData/" + this.uuid)

        .then((res) => {
          var status = res.data.original.status;
          var tamr = res.data.original.data.tamr;
          switch (status) {
            case 200:
              _this.tamrData.id = tamr.id;
              _this.tamrData.ref = tamr.ref;
              _this.tamrData.description = tamr.description;
              _this.tamrData.requestedBy = tamr.requestedBy;
              _this.tamrData.requestDate = tamr.created_at;
              _this.tamrData.requestType = tamr.type;
              _this.tamrData.requestLocationType = tamr.location;
              _this.tamrData.requestLocation = tamr.location_type;
              _this.tamrData.location_txt = tamr.location_txt;
              _this.tamrData.requestUrgency = tamr.urgency;
              _this.tamrData.status = tamr.status;
              _this.tamrData.statusPlain = tamr.statusPlain;
              _this.tamrData.maintenanceOrder = tamr.maintenanceOrder;
              _this.tamrData.modelType = tamr.modelType;
              _this.tamrData.rejectedBy = tamr.rejectedBy;
              _this.tamrData.rejectionReason = tamr.rejectionReason;
              _this.tamrData.rejectionDate = tamr.rejectionDate;
              _this.tamrData.approvedBy = tamr.approvedBy;
              _this.tamrData.entries = tamr.entries;
              _this.tamrData.reception = tamr.reception;
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="tamrData.ref" :items="items" />
    <div class="row" v-if="tamrData.statusPlain == 'draft'">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="button-items" v-if="tamrData.statusPlain == 'draft' && $can('edit_tamr')">
              <b-button
                variant="primary"
                v-if="tamrData.statusPlain == 'draft'"
              >
                <i class="bx bx-edit font-size-16 align-middle mr-2"></i>
                Modifier
              </b-button>
              <b-button
                variant="success"
                v-b-modal.approvalModal
                v-if="tamrData.statusPlain == 'draft' && $can('approve_tamr')"
              >
                <i
                  class="bx bx-check-double font-size-16 align-middle mr-2"
                ></i>
                Approuver
              </b-button>

              <b-button
                variant="danger"
                v-b-modal.rejectionModal
                v-if="tamrData.statusPlain == 'draft' && $can('reject_tamr')"
              >
                <i class="bx bx-block font-size-16 align-middle mr-2"></i>
                Rejeter
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <a
              @click="fetchSingleTakeawayRequestData()"
              href="javascript:void()"
              ><h1><i class="bx bx-rotate-right" style="float: right;"></i></h1
            ></a>
            <h4 class="card-title mb-4">Détails</h4>

            <p lass="text-muted mb-4">
              {{ tamrData.description }}
            </p>
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive mb-0">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Demandeur :</th>
                        <td>{{ tamrData.requestedBy }}</td>
                      </tr>
                      <tr>
                        <th>Destination :</th>
                        <td>
                          {{ tamrData.requestLocationType }} /
                          {{ tamrData.requestLocation }}
                        </td>
                      </tr>
                      <tr>
                        <th>Lieu de service :</th>
                        <td>
                          {{ tamrData.location_txt }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="table-responsive mb-0">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Date de demande :</th>
                        <td>{{ tamrData.requestDate }}</td>
                      </tr>
                      <tr>
                        <th>Etat :</th>
                        <td>
                          <span v-html="tamrData.status"></span>
                        </td>
                      </tr>
                      <tr v-if="tamrData.statusPlain == 'rejected'">
                        <th>Rejeté par :</th>
                        <td>{{ tamrData.rejectedBy }}</td>
                      </tr>
                      <tr v-if="tamrData.statusPlain == 'rejected'">
                        <th>Date de rejet:</th>
                        <td>{{ tamrData.rejectionDate }}</td>
                      </tr>
                      <tr v-if="tamrData.statusPlain == 'rejected'">
                        <th>Raison de rejet:</th>
                        <td>{{ tamrData.rejectionReason }}</td>
                      </tr>

                      <tr v-if="tamrData.statusPlain == 'approved'">
                        <th>Approuvée par :</th>
                        <td>{{ tamrData.approvedBy }}</td>
                      </tr>
                      <tr v-if="tamrData.statusPlain == 'approved'">
                        <th>Bon de Livraison :</th>
                        <td>
                          
                            <router-link
                          :to="{
                            name: 'ops.receptions.display',
                            params: { uid: tamrData.reception.uuid },
                          }"
                          >{{ tamrData.reception.ref }}</router-link>
                          </td>
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-lg-12">
                  <h4 class="card-title">Repas demandés</h4>
                  <p class="card-title-desc"></p>

                  <div class="table-responsive">
                    <table class="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Repas</th>
                          <th>Qty</th>
                          <th>Observations</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="entry in tamrData.entries" v-bind:key="entry.id">
                          <td>{{ entry.display_name }}</td>
                          <td>{{ entry.qty }}</td>
                          <td>{{ entry.comments }}</td>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <approval-modal :modelUid="tamrData.id" :modelRef="tamrData.ref" />
    <rejection-modal
      :modelRef="tamrData.ref"
      :modelId="tamrData.id"
      :modelType="tamrData.modelType"
    />
  </Layout>
</template>
