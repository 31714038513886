var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.tamrData.ref, items: _vm.items }
      }),
      _vm.tamrData.statusPlain == "draft"
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _vm.tamrData.statusPlain == "draft" && _vm.$can("edit_tamr")
                    ? _c(
                        "div",
                        { staticClass: "button-items" },
                        [
                          _vm.tamrData.statusPlain == "draft"
                            ? _c(
                                "b-button",
                                { attrs: { variant: "primary" } },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-edit font-size-16 align-middle mr-2"
                                  }),
                                  _vm._v(" Modifier ")
                                ]
                              )
                            : _vm._e(),
                          _vm.tamrData.statusPlain == "draft" &&
                          _vm.$can("approve_tamr")
                            ? _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.approvalModal",
                                      modifiers: { approvalModal: true }
                                    }
                                  ],
                                  attrs: { variant: "success" }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-check-double font-size-16 align-middle mr-2"
                                  }),
                                  _vm._v(" Approuver ")
                                ]
                              )
                            : _vm._e(),
                          _vm.tamrData.statusPlain == "draft" &&
                          _vm.$can("reject_tamr")
                            ? _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.rejectionModal",
                                      modifiers: { rejectionModal: true }
                                    }
                                  ],
                                  attrs: { variant: "danger" }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-block font-size-16 align-middle mr-2"
                                  }),
                                  _vm._v(" Rejeter ")
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void()" },
                  on: {
                    click: function($event) {
                      return _vm.fetchSingleTakeawayRequestData()
                    }
                  }
                },
                [
                  _c("h1", [
                    _c("i", {
                      staticClass: "bx bx-rotate-right",
                      staticStyle: { float: "right" }
                    })
                  ])
                ]
              ),
              _c("h4", { staticClass: "card-title mb-4" }, [_vm._v("Détails")]),
              _c("p", { attrs: { lass: "text-muted mb-4" } }, [
                _vm._v(" " + _vm._s(_vm.tamrData.description) + " ")
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "table-responsive mb-0" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("Demandeur :")]),
                          _c("td", [_vm._v(_vm._s(_vm.tamrData.requestedBy))])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Destination :")]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.tamrData.requestLocationType) +
                                " / " +
                                _vm._s(_vm.tamrData.requestLocation) +
                                " "
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Lieu de service :")]),
                          _c("td", [
                            _vm._v(
                              " " + _vm._s(_vm.tamrData.location_txt) + " "
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "table-responsive mb-0" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("Date de demande :")]),
                          _c("td", [_vm._v(_vm._s(_vm.tamrData.requestDate))])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Etat :")]),
                          _c("td", [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.tamrData.status)
                              }
                            })
                          ])
                        ]),
                        _vm.tamrData.statusPlain == "rejected"
                          ? _c("tr", [
                              _c("th", [_vm._v("Rejeté par :")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.tamrData.rejectedBy))
                              ])
                            ])
                          : _vm._e(),
                        _vm.tamrData.statusPlain == "rejected"
                          ? _c("tr", [
                              _c("th", [_vm._v("Date de rejet:")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.tamrData.rejectionDate))
                              ])
                            ])
                          : _vm._e(),
                        _vm.tamrData.statusPlain == "rejected"
                          ? _c("tr", [
                              _c("th", [_vm._v("Raison de rejet:")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.tamrData.rejectionReason))
                              ])
                            ])
                          : _vm._e(),
                        _vm.tamrData.statusPlain == "approved"
                          ? _c("tr", [
                              _c("th", [_vm._v("Approuvée par :")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.tamrData.approvedBy))
                              ])
                            ])
                          : _vm._e(),
                        _vm.tamrData.statusPlain == "approved"
                          ? _c("tr", [
                              _c("th", [_vm._v("Bon de Livraison :")]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "ops.receptions.display",
                                          params: {
                                            uid: _vm.tamrData.reception.uuid
                                          }
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.tamrData.reception.ref))]
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ])
                ])
              ]),
              _c("hr"),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Repas demandés")
                  ]),
                  _c("p", { staticClass: "card-title-desc" }),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table table-striped mb-0" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("Repas")]),
                          _c("th", [_vm._v("Qty")]),
                          _c("th", [_vm._v("Observations")])
                        ])
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.tamrData.entries, function(entry) {
                          return _c("tr", { key: entry.id }, [
                            _c("td", [_vm._v(_vm._s(entry.display_name))]),
                            _c("td", [_vm._v(_vm._s(entry.qty))]),
                            _c("td", [_vm._v(_vm._s(entry.comments))])
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("approval-modal", {
        attrs: { modelUid: _vm.tamrData.id, modelRef: _vm.tamrData.ref }
      }),
      _c("rejection-modal", {
        attrs: {
          modelRef: _vm.tamrData.ref,
          modelId: _vm.tamrData.id,
          modelType: _vm.tamrData.modelType
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }